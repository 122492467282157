import APIManager from './APIManager';

// ? Minor to Individual Account Transfer
const FillPFForm = async (queryObj) => {
    const formData = new FormData();

    formData.append('name', queryObj.name);
    formData.append('email', queryObj.email);
    formData.append('citizenshipNumber', queryObj.citizenshipNumber);
    formData.append('issueDateAndDistrict', queryObj.issueDateAndDistrict);
    formData.append('accountNo', queryObj.accountNo);
    formData.append('companyName', queryObj.companyName);
    formData.append('shareQuantity', queryObj.shareQuantity);
    formData.append('isin', queryObj.isin);
    formData.append('shareholderNo', queryObj.shareholderNo);
    formData.append('guardianName', queryObj.guardianName);
    formData.append('grandfatherName', queryObj.grandfatherName);
    formData.append('spouseName', queryObj.spouseName);
    formData.append('address', queryObj.address);
    formData.append('telephoneNumber', queryObj.telephoneNumber);
    formData.append('reasonForLocking', queryObj.reasonForLocking);
    formData.append('lockingDate', queryObj.lockingDate);
    formData.append('securities', JSON.stringify(queryObj.securities));
    formData.append('signatureBeneficiary', queryObj.signatureBeneficiary);
    formData.append('signatureCompany', queryObj.signatureCompany);

    const formResponse = APIManager.axios
        .post(`/api/v1/public/privatization-form`, formData)
        .then((response) => {
            return response;
        });

    return formResponse;
};

export default {
    FillPFForm,
};
